/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

a {
  color: #fb0bc7 !important;
  transition: color .3s ease !important;
}

a:hover {
  color: #fb0bc7c3 !important;
  text-decoration: underline !important;
}

.bg-light {
  background-color: transparent !important;
  color: #fff !important;
}

.navbar-light .navbar-nav .show>.nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(255, 255, 255, 0.9) !important;
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55) !important;
}

html {
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

body {
  font-family: 'Cabin', sans-serif;
  background: url('assets/img/metaverse_bg.jpg') 0 0/100% 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

.bg-light {
  background-color: transparent !important;
}

.btn-check:focus+.btn,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0;
}

.btn-outline-success {
  background: rgba(65, 12, 82, 0.2) !important;
  border: 1.97802px solid #EBC6F7 !important;
  box-sizing: border-box;
  border-radius: 27.6923px !important;
  font-family: 'Cabin', serif !important;
  font-style: normal;
  font-weight: 700;
  font-size: 1rem !important;
  line-height: 15px;
  color: #EBC6F7 !important;
  transition: all .3s ease-in-out;
}

.btn-outline-success:hover {
  background: rgba(65, 12, 82, 0.67) !important;
  border: 1.97802px solid #cd79e9 !important;
}



.dropcard {
  margin-top: 10px;
  background: #9B1AC3;
  box-shadow: 1.66127px 3.32254px 3.32254px 3.32254px rgba(0, 0, 0, 0.25);
  border-radius: 47.1801px;
  // padding: 0;
  // margin: 0;
  // overflow-x: hidden;
  position: relative;
}



h3 {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 700;
  font-size: 31.8964px;
  line-height: 39px;
  color: #FFFFFF;
}

h4 {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #FFFFFF;
}

h5 {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  // line-height: 30px;
  color: #FFFFFF;
  margin-bottom: 0;
}


.mybtn {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 39px;
  color: #FFFFFF;
  background-color: transparent;
}

p {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 500;
  font-size: 13.2902px;
  line-height: 16px;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 0;
}

p.light {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 400;
  font-size: 12.1917px;
  line-height: 15px;
  color: #FFFFFF;
  opacity: 0.56;
  margin-bottom: 0;
}




.logo {
  width: 50px;
  height: 50px;
  line-height: 50px;
  background: #FFFFFF;
  border-radius: 50%;
  text-align: center;
  margin-right: 30px;
}

.dropdown-menu3:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  bottom: 100%;
  right: 13px;
  border-width: 0 8px 8px 8px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.1) transparent;
}

.dropdown-menu3:after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  bottom: 100%;
  right: 15px;
  border-width: 0 6px 6px 6px;
  border-style: solid;
  border-color: #bf41e6 transparent;
}

.dropdown-menu3 {
  top: 110% !important;
  left: 0;
  margin-top: 0.125rem;
}

.dropdown-menu3.show {
  width: 90% !important;
  margin-left: 10px !important;
}

// .dropdown-menu-dark .dropdown-item {
//   padding: 10px 20px !important;
// }

.dropdown-item2 {
  display: block;
  width: 100%;
  padding: 1rem 1rem;
  clear: both;
  font-family: "Circular Std" !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px !important;
  line-height: 94.73% !important;
  // color: #808080 !important;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item2.active,
.dropdown-item2:active {
  color: #ffffff !important;
  background-color: rgba(255, 255, 255, 0.15) !important;
}

// .dropdown-toggle::after {
//   display: none !important;

//   content: "";
// }

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #562067 !important;
  border-color: rgba(41, 7, 53, 0.964);
}

// .card-body {
//   margin: 30px auto;
// }

// .w-400 {
//   width: 400px;
// }

a {
  text-decoration: none !important;
}



.modal-content {
  background-color: #bf41e6 !important;
  color: #fff !important;
}

.btn-primary:hover {
  color: #1B1616 !important;
  background-color: #ebc6f7c0;
  border-color: #ebc6f7b1;
}

.btn-primary {
  color: #1B1616 !important;
  box-shadow: 0 4px 17px rgba(103, 28, 127, 0.46) !important;
  background-color: #EBC6F7 !important;
  border-color: #EBC6F7 !important;
}


.page-item:not(:first-child) .page-link {
  margin-left: 9px !important;
  border-radius: 5px !important;
}

.page-item:first-child .page-link {
  border-radius: 5px !important;
}

.page-link {
  // padding: 0.5rem 1rem;
  // color: #F87774;
  color: #361042 !important;
  text-decoration: none;
  // background: #5E5B5B !important;
  border: 1px solid #5E5B5B !important;
}

.page-link:hover {
  color: #fff !important;
  background-color: #361042db !important;
  border-color: #361042e3 !important;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff !important;
  background-color: #630581 !important;
  border-color: #2a0d34 !important;
}


.form-select {
  // background: #5E5B5B !;
  box-shadow: 0 0 14px rgba(0, 0, 0, 0.14);
  border-radius: 5px;
  padding: .5rem 2rem;
  border: none;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  // color: #FFFFFF;
  // background-image: url('./assets/imgs/icon/stroke.png');
}

.form-select:focus {
  border-color: #c65f5dfe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem #c65f5d4f;
}

select option {
  background-color: #270c30 !important;
  color: rgba(255, 255, 255, 0.411) !important;
}


select:focus>option:hover {
  background: #e56e6b !important;
}

select option:checked {
  // background: #e56e6b !important;
  color: rgb(255, 255, 255) !important;
}


.table-dark {
  --bs-table-bg: #4e0565 !important;
  --bs-table-striped-bg: #610a7c !important;
  --bs-table-striped-color: #fff !important;
  --bs-table-active-bg: #3d114b !important;
  --bs-table-active-color: #fff !important;
  --bs-table-hover-bg: #323539 !important;
  --bs-table-hover-color: #fff !important;
  color: #fff;
  border-color: #320f3d !important;
}

.table> :not(caption)>*>* {
  padding: 0.5rem 0.5rem !important;
  border-bottom-width: 2px !important;
}

.table>thead>tr>* {
  padding: 0.75rem 0.75rem !important;
}

// body {
//   position: relative;
// }


// body{
//   height:100vh; margin:0;
// }
// body{
//   display:flex;
//   flex-direction:column;
// }
// header {
//     min-height: 10vh;
// }
// main{
//   min-height: 80vh;
//   display: block;
// }
// footer{
//   min-height:10vh;
//   background: rgba(35, 13, 43, 0.79) !important;
//   margin-top:auto;
// }

main {
  min-height: calc(100vh - 70px);
}
.footer {
  height: 70px;
  background: rgba(35, 13, 43, 0.79) !important;
  margin-top:auto;
}

.footer{
  // position: absolute;
  // bottom: 0;
  // left: 0;
  // right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer .my-list{
  margin: 0;
  padding: 0;
}

.footer .my-list li {
  float: left;
  margin-right: 16px;
  color: #fff !important;
}

.footer .my-list li a{

  color: #fff !important;
}

@media only screen and (max-width: 575px) {
  .w-400 {
    width: 300px;
  }
  .page-item:not(:first-child) .page-link {
  margin-left: 3px !important;
  border-radius: 2px !important;
  }
  .page-link {
    padding: 0.25rem 0.7rem !important;
}
  // main{
  //   min-height: 70vh;
  //   display: block;
  // }
}

